import React from 'react';

import { ReferralProgramID } from 'handlers/cardData';
import { formatNumber } from 'utils/formatNumber';

import { LAUNCH_WAITLIST_SIZE } from './useReferralProgramData';
import ShowDetailsLabel from './ShowDetailsLabel/ShowDetailsLabel';

enum ReferralProgramType {
  ReferralBased = 'referralBased',
  CreditBased = 'creditBased',
}

export interface ReferralProgramDisplayData {
  readonly id: ReferralProgramID;
  readonly title: string;
  readonly subtitle: string | JSX.Element;
  readonly type?: ReferralProgramType;
  readonly progressBar?: {
    readonly title: string;
    readonly showCount: boolean;
    readonly showPositionLabel: boolean;
    readonly inverted?: boolean;
    readonly isCountMoney?: boolean;
  };
  readonly thanksLabel: string;
}

const noRewards = {
  id: ReferralProgramID.NoRewards,
  title: 'Spread the Word!',
  subtitle: 'Help your fellow nurses stress less about their finances.',
  thanksLabel: 'Spread the word to your fellow nurses!',
};

const launchProgress = {
  id: ReferralProgramID.LaunchProgress,
  title: 'Help Us Launch!',
  subtitle: (
    <p>
      We're launching the Plannery card when we get to <strong>{formatNumber(LAUNCH_WAITLIST_SIZE)}</strong> spots.
    </p>
  ),
  progressBar: {
    title: 'Launch Progress',
    showCount: true,
    showPositionLabel: false,
  },
  thanksLabel: 'Spread the word and help us launch!',
};

const waitlist = {
  id: ReferralProgramID.Waitlist,
  type: ReferralProgramType.CreditBased,
  title: 'Invite Friends, Earn Rewards',
  subtitle: 'Become the first to get the Plannery card! Move up the waitlist with each referral.',
  progressBar: {
    title: 'Waitlist Position',
    showCount: false,
    showPositionLabel: true,
    inverted: true,
  },
  thanksLabel: 'Move ahead with each referral!',
};

const cashBack = {
  id: ReferralProgramID.CashBack25,
  type: ReferralProgramType.CreditBased,
  title: 'Invite Friends, Earn Rewards',
  subtitle: (
    <>
      <span>You'll both earn an additional $25 in cash back. </span>
      <ShowDetailsLabel
        content={
          <ul>
            <li>
              You'll get an additional $25 cash back from card purchases for each referral, up to a total of $200.
            </li>
            <li>During this time, your regular 2% cash back rate will go up to 3% until you reach your limit.</li>
            <li>This special offer lasts for your first year as a cardholder</li>
          </ul>
        }
      />
    </>
  ),
  progressBar: {
    title: 'Cash Back Earned',
    showCount: true,
    showPositionLabel: false,
    isCountMoney: true,
  },
  thanksLabel: 'Refer and you both earn $25 in cash back!',
};

const gales = {
  id: ReferralProgramID.Gales,
  type: ReferralProgramType.ReferralBased,
  title: 'Invite Friends, Earn Rewards',
  subtitle: '3 referrals gets you Gales shoes.',
  thanksLabel: '', // Uses dynamic label in ReferralProgramSummary
};

export const REFERRAL_PROGRAM_DISPLAY_DATA: Readonly<Record<ReferralProgramID, ReferralProgramDisplayData>> = {
  [ReferralProgramID.NoRewards]: noRewards,
  [ReferralProgramID.LaunchProgress]: launchProgress,
  [ReferralProgramID.Waitlist]: waitlist,
  [ReferralProgramID.CashBack25]: cashBack,
  [ReferralProgramID.Gales]: gales,
} as const;
