/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import clsx from 'clsx';
import { EmailShareButton, FacebookMessengerShareButton, FacebookShareButton } from 'react-share';
import { MessageShareButton } from 'components/ShareModal/ShareModal';

import { ReactComponent as FacebookIcon } from 'images/share-modal/facebook.svg';
import { ReactComponent as FacebookMessengerIcon } from 'images/share-modal/facebook-messenger.svg';
import { ReactComponent as FacebookGroupsIcon } from 'images/share-modal/facebook-groups.svg';
import { ReactComponent as EmailIcon } from 'images/share-modal/email.svg';
import { ReactComponent as MessagesIcon } from 'images/share-modal/messages.svg';
import { useSelector } from 'react-redux';
import { getCardData } from 'selectors/getCardData';

import styles from './ShareButtons.module.scss';

export const sharePlatforms = [
  {
    id: 1,
    name: 'Post On Facebook',
    button: FacebookShareButton,
    icon: FacebookIcon,
    stepsToComplete: <p>Your message will be copied. You’ll need to paste it in the Facebook popup.</p>,
  },
  {
    id: 2,
    name: 'Facebook Groups',
    button: FacebookShareButton,
    icon: FacebookGroupsIcon,
    stepsToComplete: (
      <div style={{ textAlign: 'left' }}>
        <p>Your message will be copied. Click next and:</p>
        <p>1. Select Groups in the dropdown at the top </p>
        <p>2. Paste the message.</p>
      </div>
    ),
  },
  {
    id: 3,
    name: 'Messenger',
    button: FacebookMessengerShareButton,
    icon: FacebookMessengerIcon,
    stepsToComplete: <p>Your message will be copied. You’ll need to paste it in the Messenger popup.</p>,
  },
  {
    id: 4,
    name: 'Email',
    button: EmailShareButton,
    icon: EmailIcon,
  },
  {
    id: 5,
    name: 'Text',
    button: MessageShareButton,
    icon: MessagesIcon,
  },
];

interface ShareButtonsProps {
  selected: number;
  setSelectedPlatform: (id: number) => void;
  shareMessage: string;
}

const ShareButtons = ({ selected, setSelectedPlatform, shareMessage }: ShareButtonsProps): JSX.Element => (
  <div>
    {sharePlatforms.map((platform) => (
      <div
        onClick={() => setSelectedPlatform(platform.id)}
        className={clsx(styles.shareButton, {
          [styles.active]: selected === platform.id,
        })}
        key={platform.id}
      >
        <div className={styles.radio} />
        <platform.icon className={styles.icon} />
        <div className={styles.name}>{platform.name}</div>
      </div>
    ))}
  </div>
);

export default ShareButtons;
