import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCardData } from 'selectors/getCardData';
import { ReferralData, ReferralProgramID, RewardStatus } from 'handlers/cardData';
import { ReferralProgramDisplayData, REFERRAL_PROGRAM_DISPLAY_DATA } from 'components/ReferralProgram/referralPrograms';

import { REWARD_DISPLAY_DATA, DisplayedReward } from './referralRewards';

import styles from './ReferralProgram.module.scss';

export type EnrichedReward = RewardStatus & DisplayedReward & { redeemed: boolean };

interface UseReferralProgramProps {
  programDisplayData: ReferralProgramDisplayData;
  credits: number;
  rewards: EnrichedReward[];
  nextReward: EnrichedReward | null;
  referredApplicants: ReferralData[];
  thanksLabel: string;
}

const numberToWord: Record<number, string> = {
  0: 'Zero',
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
};

const getReferredApplicantsLabel = (referredApplicants: ReferralData[]): string => {
  const referralList = referredApplicants.map((applicant) => applicant.firstName);
  const lastReferral = referralList.slice(-1);

  if (!referralList.length) {
    return '';
  }

  const thanksLabel = 'Thanks for referring ';

  if (referralList.length === 1) {
    return `${thanksLabel} ${referralList[0]}! `;
  }
  const referralNames = referralList.slice(0, -1).join(', ') + (lastReferral ? ` and ${lastReferral}` : '');
  return `${thanksLabel} ${referralNames}! `;
};

const getReferralsRemainingLabel = (enrichedMostValuableReward: EnrichedReward, referredApplicants: number): string => {
  if (!enrichedMostValuableReward.referralsToRedeem || !referredApplicants) {
    return enrichedMostValuableReward.rewardToClaimLabel || '';
  }

  const referralsLeft = enrichedMostValuableReward.referralsToRedeem - referredApplicants;
  if (referralsLeft <= 0) {
    return enrichedMostValuableReward.rewardClaimedLabel || '';
  }

  return `${numberToWord[referralsLeft]} more referral${referralsLeft > 1 ? 's' : ''} ${
    enrichedMostValuableReward.referralsLeftLabel || ''
  }`;
};

const enrichReward = (reward: RewardStatus, waitListPosition: number | undefined) => {
  const rewardDisplayData = REWARD_DISPLAY_DATA[reward.id]({ waitListPosition, styles });

  const redeemed = reward.timesRedeemed > 0;

  return {
    ...reward,
    redeemed,
    ...rewardDisplayData,
  };
};

const useReferralProgram = (): UseReferralProgramProps => {
  const { waitListPosition, referralProgram } = useSelector(getCardData);
  const { rewards, referredApplicants, credits } = referralProgram!;

  const referralCount = referredApplicants?.length || 0;
  const programDisplayData = REFERRAL_PROGRAM_DISPLAY_DATA[referralProgram?.id || ReferralProgramID.NoRewards];

  const getNextReward = useCallback((): EnrichedReward | null => {
    const enrichedRewards = enrichRewards();

    const nextRewardIndex = credits <= enrichedRewards.length ? credits : null;

    if (nextRewardIndex === null) {
      return null;
    }

    return enrichedRewards[nextRewardIndex];
  }, [referralProgram?.rewards]);

  const enrichRewards = useCallback((): EnrichedReward[] => rewards.map(enrichReward), [
    rewards,
    referralCount,
    waitListPosition,
  ]);

  const mostValuableReward: RewardStatus | undefined = rewards.reduce(
    (mostValuable, current) => (current.weightPoints > mostValuable.weightPoints ? current : mostValuable),
    rewards[rewards.length - 1],
  );

  const getThanksLabel = () => {
    const referredLabel = getReferredApplicantsLabel(referredApplicants);
    if (!mostValuableReward) {
      return referredLabel;
    }

    const referralsRemainingLabel = getReferralsRemainingLabel(
      enrichReward(mostValuableReward, waitListPosition),
      referredApplicants.length,
    );
    return referredLabel + referralsRemainingLabel;
  };

  return {
    programDisplayData,
    credits,
    rewards: enrichRewards(),
    nextReward: getNextReward(),
    referredApplicants,
    thanksLabel: getThanksLabel(),
  };
};

export default useReferralProgram;
