import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApplicationData } from 'thunks';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { RoutePath } from 'enums/Routes';
import { ApplicationStepName } from 'enums/ApplicationStepName';
import { getEnvironment, Environments } from 'utils/getEnvironment';
import { CurrentFlow } from 'enums/CurrentFlow';

import { GetApplicationData } from './applicationData';

export const FLOW_TO_STEP_MAP: Record<
  CurrentFlow,
  (application?: GetApplicationData, completedCustomize?: boolean) => { path: RoutePath; checkupNext?: RoutePath }
> = {
  [CurrentFlow.V1]: (application?: GetApplicationData) =>
    application?.status
      ? {
          [ApplicationStatusName.ManualReview]: { path: RoutePath.OfferStatus },
          [ApplicationStatusName.OfferAvailable]: { path: RoutePath.HardOffer },
          [ApplicationStatusName.Verification]: { path: RoutePath.SetupAccount },
          [ApplicationStatusName.ManualVerify]: { path: RoutePath.OfferStatus },
          [ApplicationStatusName.SetUpDeduction]: { path: RoutePath.Citizenship },
          [ApplicationStatusName.FinalReview]: { path: RoutePath.OfferStatus },
          [ApplicationStatusName.SigningAgreement]: { path: RoutePath.DocuSignSignature },
          [ApplicationStatusName.OutOfState]: { path: RoutePath.OutOfState },
          [ApplicationStatusName.AlreadyApplied]: { path: RoutePath.OfferStatus },
          [ApplicationStatusName.Approved]: { path: RoutePath.FundsSent },
          [ApplicationStatusName.Rejected]: { path: RoutePath.OfferStatus },
        }[application.status]
      : { path: RoutePath.Offer },
  [CurrentFlow.FinancialCheckup]: (application?: GetApplicationData) =>
    application?.status
      ? {
          [ApplicationStatusName.ManualReview]: { path: RoutePath.YourFinances },
          [ApplicationStatusName.OfferAvailable]: { path: RoutePath.YourSavings },
          [ApplicationStatusName.Verification]: { path: RoutePath.SetupAccount },
          [ApplicationStatusName.ManualVerify]: { path: RoutePath.YourFinances, checkupNext: RoutePath.OfferStatus },
          [ApplicationStatusName.SetUpDeduction]: { path: RoutePath.Citizenship },
          [ApplicationStatusName.FinalReview]: { path: RoutePath.YourFinances, checkupNext: RoutePath.OfferStatus },
          [ApplicationStatusName.SigningAgreement]: { path: RoutePath.DocuSignSignature },
          [ApplicationStatusName.OutOfState]: { path: RoutePath.YourFinances, checkupNext: RoutePath.OfferStatus },
          [ApplicationStatusName.AlreadyApplied]: { path: RoutePath.YourFinances, checkupNext: RoutePath.OfferStatus },
          [ApplicationStatusName.Approved]: { path: RoutePath.FundsSent },
          [ApplicationStatusName.Rejected]: { path: RoutePath.YourFinances, checkupNext: RoutePath.OfferStatus },
        }[application.status]
      : { path: RoutePath.YourName },
  [CurrentFlow.Card]: (application?: GetApplicationData, completedCustomize?: boolean) => {
    let path;
    if (application?.status) {
      path = RoutePath.CardApplied;
    } else if (completedCustomize) {
      path = RoutePath.YourPhoneNumber;
    } else {
      path = RoutePath.YourName;
    }
    return { path };
  },
  [CurrentFlow.MissedPayment]: () => ({ path: RoutePath.MissedPayment }),
};

interface ApplicationStep {
  stepName?: RoutePath;
  currentFlow?: CurrentFlow;
  completedCustomize?: boolean;
}

const initialState: ApplicationStep = {};

const updateAnalytics = (state: ApplicationStep) => {
  const commonKey = Object.entries(RoutePath).find(([, val]) => val === state.stepName)?.[0];
  const step = Object.entries(ApplicationStepName).find(([key]) => key === commonKey)?.[1];

  if (!step && getEnvironment() === Environments.Staging) {
    throw Error(`Missing ApplicationStepName declaration for ${commonKey}`);
  }

  analytics.track(`${step} Step`);
  (window as any).nid('start', step);
};

const applicationStep = createSlice({
  name: 'applicationStep',
  initialState,
  reducers: {
    setApplicationStep: (state: ApplicationStep, { payload }: PayloadAction<RoutePath>) => {
      state.stepName = payload;
      updateAnalytics(state);
    },
    setApplicationFlow: (state: ApplicationStep, { payload }: PayloadAction<ApplicationStep>) => {
      state.currentFlow = payload.currentFlow;
      state.completedCustomize = payload.completedCustomize;
      analytics.identify({ currentFlow: payload.currentFlow });
      state.stepName =
        payload.stepName || FLOW_TO_STEP_MAP[state.currentFlow!](undefined, payload.completedCustomize).path;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApplicationData.fulfilled, (state, { payload }) => {
      state.currentFlow = payload.application.currentFlow || CurrentFlow.V1;
      state.stepName = FLOW_TO_STEP_MAP[state.currentFlow](payload.application).path;
    });
  },
});

export const { setApplicationStep, setApplicationFlow } = applicationStep.actions;
export default applicationStep.reducer;
